<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm8>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        <span>Batches</span>
                        <v-spacer></v-spacer>
                        <add-button :onCurrentBatch="false" permission="batch-create" @action="form.dialog = true">New Batch</add-button>
                    </v-card-title>

                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="form.items.data"
                            :search="search"
                            :footer-props="rowsPerPageItems"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <td class="text-right">
                                    <edit-button permission="batch-update" @agree="form.edit(item)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm4>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        <v-spacer/>
                        <v-btn icon ma-0 small @click="setChart()">
                            <v-icon small>autorenew</v-icon>
                        </v-btn>
                    </v-card-title>
                    <apexchart v-if="chartData.length" type="pie" :options="chartOptions" :series="chartData"/>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
                <v-card outlined class="primary--border">
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <alert-message
                                type="warning" :extraSmall="true"
                                :title="`Batch alert!! Selected (${batch.name})`"
                                message="Please switch or select the batch if your are copying the previous records i.e Grades, Sections and Subjects">
                        </alert-message>
                        <div class="mt-2">
                            <v-form ref="form" @submit.prevent="store"
                                    @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                    lazy-validation>

                                <v-container grid-list-md>
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <v-text-field
                                                    label="Batch Name*" autocomplete="off" required class="pa-0"
                                                    v-model="form.name" name="name" outlined dense
                                                    :error-messages="form.errors.get('name')"/>
                                        </v-flex>


                                        <v-flex xs12 sm6>
                                            <v-calendar-field v-model="form.batch_start" id-val="start_date"
                                                              label="Start Date"></v-calendar-field>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                            <v-calendar-field
                                                    id-val="end_date"
                                                    v-model="form.batch_end"
                                                    label="End Date">
                                            </v-calendar-field>
                                        </v-flex>

                                        <v-flex xs12>
                                            <v-checkbox
                                                    style="margin-right: 20px;" color="blue"
                                                    v-model="form.enable_advance">
                                                <template v-slot:label>
                                                    <div style="color:#333;">
                                                        {{form.enable_advance?'Advance Batch Enabled':'Enable Advance Batch'}}
                                                        <p style="line-height: 10px">
                                                            <small style="color:#666;font-size: 10px;">
                                                                Copy grade,sections,subjects etc from old batch.
                                                            </small>
                                                        </p>
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                        </v-flex>

                                        <v-flex xs12 v-if="form.enable_advance">
                                            <v-select disabled persistent-hint
                                                      hint="These grades will be copied to your newly created batch by default"
                                                      :items="grades" v-model="form.grades" label="Select Grades"
                                                      multiple
                                                      class="pt-0" outlined dense>
                                                <v-list-item slot="prepend-item" ripple @click="toggle">
                                                    <v-list-item-action>
                                                        <v-icon :color="form.grades.length > 0 ? 'indigo darken-4' : ''">
                                                            {{ icon }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-title>Select All</v-list-item-title>
                                                </v-list-item>
                                                <v-divider slot="prepend-item" class="mt-2"></v-divider>
                                                <v-divider slot="append-item" class="mb-2"></v-divider>
                                            </v-select>
                                        </v-flex>


                                        <v-flex v-if="form.enable_advance" xs12>
                                            <br>
                                            <div class="batch-migration">
                                                <label>Batch Migration</label>
                                                <p>Select the things you want to migrate along with the batch.</p>
                                                <div class="select-list">
                                                    <div>
                                                        <v-checkbox
                                                                style="margin-right: 20px;" color="blue"
                                                                v-model="form.copy_section">
                                                            <template v-slot:label>
                                                                <div style="color:#333;">
                                                                    Copy all sections
                                                                    <p style="line-height: 10px">
                                                                        <small style="color:#666;font-size: 10px;">
                                                                            All the sections from previous batch are
                                                                            copied.
                                                                        </small>
                                                                    </p>
                                                                </div>
                                                            </template>
                                                        </v-checkbox>
                                                    </div>
                                                    <div>
                                                        <v-checkbox
                                                                style="margin-right: 20px;" color="blue"
                                                                v-model="form.copy_subject">
                                                            <template v-slot:label>
                                                                <div style="color:#333;">
                                                                    Copy Subjects
                                                                    <p style="line-height: 10px">
                                                                        <small style="color:#666;font-size: 10px;">
                                                                            Check to copy all the subjects from previous
                                                                            batch.
                                                                        </small>
                                                                    </p>
                                                                </div>
                                                            </template>
                                                        </v-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-flex>

                                    </v-layout>
                                </v-container>
                            </v-form>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success"
                               outlined
                               text
                               :loading="form.loading"
                               @click="store">Save
                        </v-btn>

                        <!--<load-button  @action="store" color="success" text >Save</load-button>-->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    // import Calendar from '@/components/Calendar'
    import {validateDate} from "../../../../library/helpers";
    import {mapState} from 'vuex';


    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    const year = parseInt(nd.getYear()) + 1;

    export default {
        // components: {
        //     Calendar
        // },
        props: {
            schoolId: {
                required: false,
            }
        },
        data: () => ({
            form: new Form({
                dialog: false,
                name: 'Batch ' + year,
                batch_start: year + '-01-01',
                batch_end: year + '-12-31',
                copy_subject: false,
                copy_section: false,
                copy_routine: false,
                enable_advance: false,
                grades: [],
            }, '/api/batch'),
            search: null,
            pagination: {
                itemsPerPage: 25
            },
            rowsPerPageItems: {
                'items-per-page-options': [5, 10, 25, 50, 75],
            },
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 80},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            grades: [],
            batchStart: '',
            batchEnd: '',
            chartData: [],
            chartOptions: {
                title: {
                    text: 'Batch-Wise Students',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            }
        }),
        watch: {
            'pagination': function () {
                this.get();
            },
            'form.dialog': function (value) {
                if (value === true) this.getGrades();
            }
        },
        computed: {
            ...mapState(['batch']),
            selectAllGrades() {
                return this.form.grades.length === this.grades.length
            },
            selectFewGrades() {
                return this.form.grades.length > 0 && !this.selectAllGrades
            },
            icon() {
                if (this.selectAllGrades) return 'check_box';
                if (this.selectFewGrades) return 'indeterminate_check_box';
                return 'add_box'
            }
        },

        mounted() {
            $(document).ready(function () {

                // $('#batch_start,#batch_end').nepaliDatePicker({
                //     npdMonth: true,
                //     npdYear: true,
                // });
            });
        },

        methods: {

            updateExpiryDate(value) {
            },
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&') + (this.schoolId ? `&schoolId=${this.schoolId}` : '');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total;
                    this.setChart(data.data);
                })
            },

            store() {
                this.form.batch_start = this.form.batch_start.dateForm();
                this.form.batch_end = this.form.batch_end.dateForm();
                this.form.store();
            },

            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {text: item.name, value: item.id};
                    });
                    this.form.grades = this.grades;
                })
            },

            toggle() {
                this.$nextTick(() => {
                    if (this.selectAllGrades) {
                        this.form.grades = []
                    } else {
                        this.form.grades = this.grades.map(grade => {
                            return grade.value;
                        })
                    }
                })
            },

            setChart(data) {
                if (data == null) {
                    data = this.form.items.data
                }

                let labels = data.map(item => {
                    return item.name
                });

                this.chartOptions.labels = labels;

                this.chartData = data.map(() => {
                    return Math.floor(Math.random() * Math.floor(50));
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .batch-migration {
        border: 1px solid #ccc;
        padding: 15px 15px 0 15px !important;
        position: relative;
        margin-top: 20px;
        label {
            font-size: 18px;
            position: absolute;
            top: -16px;
            background-color: white;
            padding: 0 7px;
            + p {
                position: absolute;
                top: 8px;
                padding-left: 09px;
                font-size: 13px;
                color: #c1c1c1;
            }
        }
        .v-input--selection-controls {
            margin-top: 5px;
        }
        .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
            margin-bottom: 0 !important;
            /*height: 37px!important;*/
        }
        .v-input__slot {
            margin-bottom: 0 !important;
        }
        .select-list {
            padding: 20px 0;
        }
        p {
            margin-bottom: 0 !important;
        }
    }

</style>