<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="elevation-1">
          <div class="tab-panel-">
            <v-tabs v-model="model" color="white" slider-color="primary">
              <v-tab v-for="(tab, i) in tabItems" :key="i" :href="`#tab-${i}`">
                {{ tab }}
              </v-tab>
            </v-tabs>
          </div>
          <!--</div>-->
        </v-card>
        <div class="dash-container info-tab-section" style="width: 100%">
          <div v-if="model === 'tab-0'" class="billing-setting">
            <div class="settings flex xs12">
              <v-card>
                <div class="pa-5">
                  <add-button
                    class="pr-2"
                    style="margin-top: -15px;margin-bottom: -10px;"
                    permission="grade-create"
                    @action="form.dialog = true"
                    >Billing Month
                  </add-button>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="form.items.data"
                  :search="search"
                  hide-default-footer
                  :options.sync="pagination"
                  :server-items-length="form.items.meta.total"
                  :loading="form.loading"
                >
                  <template v-slot:item="{ index, item }">
                    <tr>
                      <td>{{ index + form.items.meta.from }}</td>
                      <td class="text-xs-left">{{ item.billing }}</td>
                      <td class="text-xs-left">{{ item.generate_on }}</td>
                      <td class="text-xs-left">{{ item.month_name }}</td>
                      <td class="text-xs-right">
                        <edit-button
                          permission="fee-heads-update"
                          @agree="form.edit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                  <v-alert
                    slot="no-results"
                    :value="true"
                    color="error"
                    icon="warning"
                  >
                    Your search for "{{ search }}" found no results.
                  </v-alert>
                </v-data-table>
              </v-card>

              <v-dialog v-model="form.dialog" persistent max-width="300px">
                <v-card>
                  <v-card-title class="primary white--text">
                    <span class="title">Add/Update</span>
                  </v-card-title>
                  <v-card-text class="pb-1">
                    <br />
                    <v-form
                      ref="form"
                      @submit.prevent="save"
                      @keydown.native="form.errors.clear($event.target.name)"
                      @keyup.enter="save"
                      lazy-validation
                    >
                      <v-select
                        :items="billings"
                        class="pa-0"
                        label="Billing"
                        v-model="form.billing"
                      />
                      <v-select
                        :items="months"
                        class="pa-0"
                        label="Generate On"
                        v-model="form.generate_on"
                      />

                      <v-select
                        v-model="form.generate_month"
                        :items="months"
                        label="Select Grades"
                        multiple
                        class="pt-0"
                      >
                        <v-list-item slot="prepend-item" ripple @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                form.generate_month.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item>
                        <v-divider slot="prepend-item" class="mt-2"></v-divider>
                        <v-divider slot="append-item" class="mb-2"></v-divider>
                      </v-select>
                      <small>*indicates required field</small>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="warning"
                      outlined
                      @click="(form.dialog = false), form.reset()"
                    >
                      Close
                    </v-btn>
                    <v-btn color="success" outlined @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div v-if="model === 'tab-1'" class="billing-setting">
            <exam></exam>
            <div class="settings flex xs12">
              <v-dialog v-model="formVerificationDialog" max-width="450px">
                <v-card>
                  <v-card-title class="title pa-3 warning white--text">
                    <v-icon class="mr-2 white--text">warning</v-icon>
                    Please, wait!
                  </v-card-title>
                  <v-card-text class="ma-0"
                    >Are you sure you want to change verfication?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="warning"
                      outlined
                      small
                      @click="formVerificationDialog = false"
                      >No
                    </v-btn>
                    <v-btn
                      color="success"
                      outlined
                      small
                      @click="
                        (formVerificationDialog = false), updateVerification()
                      "
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div v-if="model === 'tab-2'" class="billing-setting">
            <div class="settings flex xs12">
              <school-batch :schoolId="$route.params.id"></school-batch>
            </div>
          </div>
          <div v-if="model === 'tab-3'" class="billing-setting">
            <v-card>
              <payments :schoolId="$route.params.id"></payments>
            </v-card>
          </div>

          <div v-if="model === 'tab-4'" class="enroll-manager">
            <v-card>
              <enroll-manager :schoolId="$route.params.id"></enroll-manager>
              <!--<payments :schoolId="$route.params.id"></payments>-->
            </v-card>
          </div>

          <div v-if="model === 'tab-5'" class="enroll-manager">
            <v-card>
              <student-manager :schoolId="$route.params.id"></student-manager>
              <!--<payments :schoolId="$route.params.id"></payments>-->
            </v-card>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import Exam from "../SchoolSetting/components/Exam";
import Payments from "../SchoolSetting/components/Payments";
import EnrollManager from "../SchoolSetting/components/EnrollManager";
import StudentManager from "../SchoolSetting/components/StudentManager";
import { mapState } from "vuex";
import SchoolBatch from "@/modules/academic/_components/Batch/index";

export default {
  components: {
    exam: Exam,
    SchoolBatch,
    Payments,
    EnrollManager,
    StudentManager,
  },
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    yes: false,
    formVerificationDialog: false,
    id: "",
    //exam verification setting end

    model: "tab-3",
    schoolInfo: {},
    tabItems: [
      "Billing Month",
      "Exam Verification",
      "Batches",
      "Payments",
      "Enroll Manager",
      "Student Manager",
    ],
    form: new Form(
      {
        billing: "",
        school_id: "",
        generate_on: "",
        generate_month: [],
      },
      "/api/billing-month"
    ),
    search: null,
    pagination: {
      rowsPerPage: 12,
    },
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Billing", align: "left", value: "title" },
      { text: "Generate On", align: "left", value: "description" },
      { text: "Generate Months", align: "left", value: "description" },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    billings: [
      { value: "1st", text: "1st" },
      { value: "2nd", text: "2nd" },
      { value: "3rd", text: "3rd" },
      { value: "4th", text: "4th" },
      { value: "5th", text: "5th" },
      { value: "6th", text: "6th" },
      { value: "7th", text: "7th" },
      { value: "8th", text: "8th" },
      { value: "9th", text: "9th" },
      { value: "10th", text: "10th" },
      { value: "11th", text: "11th" },
      { value: "12th", text: "12th" },
    ],
    months: [
      { value: "01", text: "Baishakh" },
      { value: "02", text: "Jestha" },
      { value: "03", text: "Asar" },
      { value: "04", text: "Shrawan" },
      { value: "05", text: "Bhadra" },
      { value: "06", text: "Asoj" },
      { value: "07", text: "Kartik" },
      { value: "08", text: "Mangsir" },
      { value: "09", text: "Poush" },
      { value: "10", text: "Magh" },
      { value: "11", text: "Phalgun" },
      { value: "12", text: "Chaitra" },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
    selectAllGenerateMonths() {
      return this.form.generate_month.length === this.months.length;
    },
    selectFewGenerateMonths() {
      return (
        this.form.generate_month.length > 0 && !this.selectAllGenerateMonths
      );
    },
    icon() {
      if (this.selectAllGenerateMonths) return "check_box";
      if (this.selectFewGenerateMonths) return "indeterminate_check_box";
      return "add_box";
    },
  },
  mounted() {
    this.get();
  },
  watch: {},
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&schoolId=" +
        this.$route.params.id
      );
    },

    getSubjects() {
      this.subjectLoading = true;
      this.$rest
        .get(
          "/api/mark-subject-list?gradeId=" +
            this.grade +
            "&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.subjects = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.subjectLoading = false;
        });
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        // this.form.generate_month = this.months;
        // console.log(this.form.generate_month);
      });
    },
    save() {
      this.form.school_id = this.schoolInfo.id;
      this.form.generate_month = this.form.generate_month.toString();
      this.form.store();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllGenerateMonths) {
          this.form.generate_month = [];
        } else {
          this.form.generate_month = this.months.map((genMonth) => {
            return genMonth.value;
          });
        }
      });
    },
    getVerificationList() {
      this.verificationLoading = true;
      this.$rest
        .get(
          "/api/exam-verify-log?examId=" +
            this.exam +
            "&gradeId=" +
            this.grade +
            "&sectionId=" +
            this.section
        )
        .then((data) => {
          this.verificationLoading = false;
          this.verificationData = data.data;
        });
    },
    editVerification(id) {
      console.log("testing");
      this.formVerificationDialog = true;
      this.id = id;
      //
    },
    updateVerification() {
      this.$rest.put("/api/exam-verify-log/" + this.id).then((data) => {
        this.verificationLoading = false;
        this.formVerificationDialog = false;
        this.id = "";
        this.getVerificationList();
        this.$events.fire("notification", {
          message: data.message,
          status: "success",
        });
      });
    },
  },
};
</script>
<style lang="scss">
.container {
  /*padding: 0 !important;*/
}

.v-tabs__item {
  color: #888;
}

.switchButton {
  margin-bottom: 18px;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.info {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.setting-container {
  display: flex;
  flex-direction: column;
  .v-list--dense {
    flex: 1;
    span {
      width: 100%;
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
    }
    div[role="listitem"]:last-child {
      margin-top: auto;
    }
    /*background-color: red;*/
  }
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;
  div.dash-company-info {
    padding-top: 40px;
    display: flex;
    padding-bottom: 10px;
  }
  h1 {
    color: #555;
    font-weight: 300;
    + p {
      color: #888;
      font-size: 14px;
    }
  }
  .navigate-to {
    flex: 1;
    padding-top: 15px;
    ul li {
      list-style: none;
      display: inline;
      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-left: 10px;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        text-decoration: none;
        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }
  .intro {
    flex: 3;
  }
  .submitButton {
    width: 108%;
    background: blue;
    margin-left: -15px;
    margin-top: 6px;
    color: black;
    height: 40px;
  }
}

.update-item {
  margin-bottom: 20px;
  .caption {
    color: #999;
    strong {
      color: #777;
    }
  }
}
</style>
